import soledis from './global';

const sldSearchWidgetRemoveClick = () => {
  const searchBar =document.querySelector('#header .search-widget-form');
  const button = document.querySelector('#header .search-widget-button');
  if (searchBar !== null && button !== null) {
    button.addEventListener('click', (e) => {
      let field = document.querySelector('#header .search-widget-field');
      if (!searchBar.classList.contains('active')) {
        e.preventDefault();
        searchBar.classList.add('active');
        field.focus();
        let overlay = document.createElement('div');
        overlay.classList.add('sld-overlay');
        overlay.addEventListener('click', () => {
          searchBar.classList.remove('active');
          overlay.remove();
        })
        document.body.append(overlay);
      }
    });
  }
}

const showSearchBar = (e) => {
  let searchBar = document.querySelector('.mobile-header-version .searchbar-wrapper');
  let searchInput = document.querySelector('.mobile-header-version .search-widget-field');
  let btn = document.querySelector('.mobile-header-version .sld-js-search-open');
  if (searchBar !== null && searchInput !== null && btn !== null) {
    if (e.type === 'click') {
      searchBar.classList.add('sld-focus');
      searchInput.focus();
      btn.style.opacity = 0;
    }
    else if (e.type === 'focusout') {
      searchBar.classList.remove('sld-focus');
      btn.style.opacity = '';
    }
  }
}

const displaySearchMobile = () => {
  let btn = document.querySelector('.mobile-header-version .sld-js-search-open');
  let searchInput = document.querySelector('.mobile-header-version .search-widget-field');
  if (btn !== null && searchInput !== null) {
    btn.addEventListener('click', showSearchBar);
    searchInput.addEventListener('focusout', showSearchBar);
  }
}

const stickySearchbarMobile = () => {
  let searchBar = document.querySelector('.mobile-header-version .header-bottom');
  if (searchBar !== null) {
    const options = {
      rootMargin: '0px',
      threshold: 1.0
    }
    
    const callback = (entries) => {
      if (entries[0].isIntersecting) {
        entries[0].target.classList.remove('sld-sticky');
        document.body.classList.remove('sld-sticky-search-bar');
      }
      else {
        entries[0].target.classList.add('sld-sticky');
        document.body.classList.add('sld-sticky-search-bar');
      }
    }
    
    const observer = new IntersectionObserver(callback, options);
  
    observer.observe(searchBar);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // remove comment if you want the animation to display search bar
  // sldSearchWidgetRemoveClick();
  displaySearchMobile();
  stickySearchbarMobile();
});