import ScrollSmooth from "../soledis/animates/scroll-smooth";

function stickyHeader() {
  const $stickyMenu = $('.desktop-header-version [data-sticky-menu]');
  const $mobileStickyMenu = $('.mobile-header-version [data-mobile-sticky]');
  if ($stickyMenu.length) {
    $stickyMenu.sticky({
      wrapperClassName: 'desktop-sticky-wrapper',
    });

    $('[data-sticky-cart]').html($('[data-header-cart-source]').html());
  }
  if ($mobileStickyMenu.length) {
    $mobileStickyMenu.sticky({
      wrapperClassName: 'mobile-sticky-wrapper',
    });
  }
}

function scrollToTopButton() {
  const $sttb = $('[data-scroll-to-top]');
  $(window).on('scroll', function() {
    if ($(this).scrollTop() > 100) {
      $sttb.fadeIn();
    } else {
      $sttb.fadeOut();
    }
  });

  $('a', $sttb).on('click', function(e) {
    e.preventDefault();
    new ScrollSmooth(0);
  });
}

function ajaxLoadSidebarCategoryTree() {
  let $sbct = $('.js-sidebar-category-tree');
  if ($sbct.length) {
    $.ajax({
      type: 'GET',
      url: $sbct.data('categorytree-controller'),
      data: {
        //ajax: true,
      },
      success: function (data) {
        $sbct.html(data);
        sidebarCategoryTreeConfig();
      },
      error: function (err) {
        console.log(err);
      },
    });

    let sidebarCategoryTreeConfig = function() {
      const $subcats = $('.js-sidebar-categories');
      if ($subcats.length) {
        $subcats.find('.js-collapse-trigger').on('click', function() {
          if (!$(this).hasClass('opened')) {
            let $p = $(this).closest('.js-sidebar-categories, .js-sub-categories');
            $p.find('.js-sub-categories.expanded').slideUp().removeClass('expanded');
            $p.find('.js-collapse-trigger.opened').removeClass('opened').find('.add, .remove').toggle();
          }
          $(this).parent().find(' > .js-sub-categories').stop().slideToggle().toggleClass('expanded');
          $(this).toggleClass('opened').find('.add, .remove').toggle();
        });
      }

      const currentCatID = $('.js-category-page').data('current-category-id');
      if (currentCatID !== 'undefined' && currentCatID !== '') {
        const $currentSBCatObj = $('.js-sidebar-categories [data-category-id=' + currentCatID + ']');

        $currentSBCatObj.addClass('current');
        $currentSBCatObj.parents('li').each(function() {
          $(this).children('.js-sub-categories').addClass('expanded').show();
          $(this).find(' > .js-collapse-trigger').addClass('opened');
          $(this).find(' > .js-collapse-trigger .add').hide();
          $(this).find(' > .js-collapse-trigger .remove').show();
        });
      }
    };
  }
}

function sidebarClose(btnMenu) {
  document.body.classList.remove('st-effect-left', 'st-menu-open');
  btnMenu.classList.remove('active');
  setTimeout(() => {
    const dropdowns = document.querySelectorAll('.adropdown.active');
    const menu = document.querySelector('.anav-top');
    if (dropdowns.length > 0) {
      dropdowns.forEach((dropdown) => {
        dropdown.classList.remove('active');
      });
    }
    if (menu !== null) {
      menu.style.height = '';
    }
  }, 500);
}

function sidebarOpenPosition(sidebar, btnMenu) {
  const headerMobile = document.querySelector('.mobile-header-version');
  const headerDesktop = document.querySelector('.desktop-header-version');
  const sidebarMenuHeader = document.querySelector('.st-menu-header');
  const sidebarCartTop = document.querySelector('.st-cart-header');
  const sidebarSidebarTop = document.querySelector('.st-sidebar-top-header');
  const menuMobileSticky = document.querySelector('.mobile-sticky-wrapper.is-sticky');
  const menuDesktopSticky = document.querySelector('.desktop-sticky-wrapper.is-sticky');
  const positionScroll = window.scrollY;
  let menuHeight = 'initial';
  let searchBarHeight = 0;
  if (sidebar === 'menu') {
    searchBarHeight = 0;
  }
  if (headerMobile !== null && sidebarMenuHeader !== null) {
    const menuStickyHeight = document.querySelector('[data-mobile-sticky]').offsetHeight;
    if (menuMobileSticky !== null) {
      menuHeight = menuStickyHeight + searchBarHeight;
    } else {
      if (searchBarHeight === 0 && positionScroll === 0 && headerMobile.offsetHeight > 100) {
        menuHeight = headerMobile.offsetHeight / 2;
      } else {
        menuHeight = headerMobile.offsetHeight + searchBarHeight - positionScroll;
      }
    }
    sidebarMenuHeader.style.minHeight = `${menuHeight}px`;
    if(typeof(sidebarCartTop) != 'undefined' && sidebarCartTop !== null){
        sidebarCartTop.style.minHeight = `${menuHeight}px`;
    }
    sidebarSidebarTop.style.minHeight = `${menuHeight}px`;
  } else if (headerDesktop !== null && sidebarMenuHeader !== null) {
    const menuStickyHeight = document.querySelector('[data-sticky-menu]').offsetHeight;
    if (menuDesktopSticky !== null) {
      menuHeight = menuStickyHeight + searchBarHeight;
    } else {
      menuHeight = headerDesktop.offsetHeight + searchBarHeight - positionScroll;
    }
    sidebarMenuHeader.style.minHeight = `${menuHeight}px`;
    sidebarSidebarTop.style.minHeight = `${menuHeight}px`;
    if(typeof(sidebarCartTop) != 'undefined' && sidebarCartTop !== null) {
      sidebarCartTop.style.minHeight = `${menuHeight}px`;
    }
  }
  if (sidebar === 'menu') {
    document.body.classList.add('st-effect-left', 'st-menu-open');
    btnMenu.classList.add('active');
  }
}

function removeClassBurgerMenu() {
  const burger = document.querySelector('.header-main-content .left-nav-trigger.active');
  if (burger !== null) {
    burger.classList.remove('active');
  }
}

function loadSidebarTop() {
  const sidebarTop = document.querySelector('.st-menu.st-effect-top');
  const sidebarSearchOpen = document.querySelectorAll('.sld-js-search-open, .sld-js-menu-left-search');
  const sidebarSearchClose = document.querySelector('.sld-js-search-close');
  const sidebarSearchInput = document.querySelector('.search-widget-field');
  if (sidebarSearchOpen.length > 0 && sidebarTop !== null && sidebarSearchClose !== null) {
    sidebarSearchOpen.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault();
        document.body.classList.add('st-effect-top', 'st-menu-open');
        sidebarOpenPosition('top');
        sidebarSearchInput.focus();
      });
    });
    sidebarSearchClose.addEventListener('click', (e) => {
      e.preventDefault();
      document.body.classList.remove('st-effect-top', 'st-effect-left', 'st-effect-right', 'st-menu-open');
      removeClassBurgerMenu();
    });
  }
}

function loadSidebarNavigation() {
  const barResponsive = document.querySelector('.st-menu.st-effect-left');
  const btnMenu = document.querySelector('[data-left-nav-trigger]');
  if (barResponsive !== null && btnMenu !== null) {
    btnMenu.addEventListener('click', () => {
      if (btnMenu.classList.contains('active')) {
        sidebarClose(btnMenu);
      } else {
        sidebarOpenPosition('menu', btnMenu);
      }
    });
  }
}

function loadSidebarCart() {
  const sidebarRight = document.querySelector('.st-menu-right[data-st-cart]');
  if (sidebarRight !== null) {
    const sidebarCart = document.querySelector('#js-cart-sidebar');
    const cartDropdown = document.querySelector('#header [data-shopping-cart-source]');
    const btnCart = document.querySelector('#header [data-sidebar-cart-trigger]');
    const btnCartClose = document.querySelectorAll('[data-close-st-cart]');
    const cartDropdownList = document.querySelector('.cart-dropdown-wrapper .cart-items');
    // move car dropdown in sidebar right
    sidebarCart.appendChild(cartDropdown.querySelector('.cart-dropdown-wrapper'));
    // cartDropdown.remove();
    sidebarCart.classList.remove('js-hidden');
    btnCart.addEventListener('click', (e) => {
      e.preventDefault();
      sidebarOpenPosition('cart');
      document.body.classList.add('st-effect-right', 'st-menu-open');
      const headerHeight = document.querySelector('.st-cart-header');
      const cartDropdownBottomHeight = document.querySelector('.cart-dropdown-wrapper .cart-bottom');
      const cartDropdownHeaderHeight = 80;
      if (headerHeight !== null && cartDropdownBottomHeight !== null) {
        const listProductMaxHeight = screen.height - (headerHeight.offsetHeight + cartDropdownBottomHeight.offsetHeight + cartDropdownHeaderHeight);
        if (cartDropdownList !== null) {
          cartDropdownList.style.maxHeight = `${listProductMaxHeight}px`;
        }
      }
    });
    btnCartClose.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        document.body.classList.remove('st-effect-right', 'st-effect-left', 'st-menu-open');
        removeClassBurgerMenu();
      });
    });
  }
}

function handleCookieMessage() {
  var $cookieMsg = $('.js-cookieMessage');
  if ($cookieMsg.length) {
    setTimeout(() => {
      $cookieMsg.cookieBar({
        closeButton : '.js-cookieCloseButton',
        path: prestashop.urls.base_url.substring(prestashop.urls.shop_domain_url.length),
      });
    }, 2000);
  }
}

function lazyItemMobileSliderScroll() {
  $('.js-items-mobile-slider').each(function() {
    $(this).on('scroll', function () {
      if($(this).scrollLeft()) {
        $('img.js-lazy', $(this)).trigger('appear');
      }
    });
  });
}

function sldSliderPrestablog() {
  const slider = $('#index #prestablog-home-list');
  if (slider !== null) {
    const sliderIsInit = slider.hasClass('slick-slider');
    if (window.innerWidth > 767 && !sliderIsInit) {
      slider.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        arrows: true,
        draggable: true,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
            slidesToShow: 2,
            }
          },
        ],
      });
    }
    else if (window.innerWidth <= 767 && sliderIsInit) {
      slider.slick('unslick');
    }
  }
}

window.addEventListener('DOMContentLoaded', () => {
  handleCookieMessage();
  $('img.js-lazy').lazyload({
    failure_limit: 9999,
    load : function() {
      $(this).removeClass('js-lazy');
    },
  });
  stickyHeader();
  scrollToTopButton();
  loadSidebarNavigation();
  loadSidebarCart();
  // loadSidebarTop();
  lazyItemMobileSliderScroll();
  sldSliderPrestablog();
});

window.addEventListener('resize', () => {
  sldSliderPrestablog();
});